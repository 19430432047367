import { Card, CardContent, CardHeader, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { AttachMoneyTwoTone as DollarIcon } from '@material-ui/icons';
import React from 'react';
import CheckedLineItem from '../../components/CheckedLinteItem';
import MainLogoAvatar from '../../MainLogoAvatar';
import colors from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
  contactCardHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  contactCardContent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  icon: {
    fontSize: '3rem',
  },
  gridItem: {
    marginBottom: theme.spacing(3),
  },
}));

const useCommonPackageStyles = makeStyles((theme) => ({
  whatsIncludedHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: colors.headingText,
  },
  packagePriceContainer: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(4),
    },
  },
  packagePrice: {
    color: colors.headingText,
  },
}));

const WhatsIncludedSubheader = () => {
  const classes = useCommonPackageStyles();
  return (
    <Typography className={classes.whatsIncludedHeading} variant="h6">
      What is included?
    </Typography>
  );
};

const ContactMeDetails = () => (
  <Typography variant="body1">
    <p>
      To find out more, <Link href="/contact">contact me</Link> for a free 15 minute chat.
    </p>
  </Typography>
);

type PackageCardSummaryProps = { summaryText: string };
const PackageCardSummary = ({ summaryText }: PackageCardSummaryProps) => (
  <Typography variant="body1">
    <p>{summaryText}</p>
  </Typography>
);

type PackageCardTitleProps = { titleText: string; subtitleText: string; price: string };
const PackageCardTitle = ({ titleText, subtitleText, price }: PackageCardTitleProps) => {
  const classes = useCommonPackageStyles();
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h4">{titleText}</Typography>
        <Typography variant="h6">{subtitleText}</Typography>
      </Grid>
      <Grid className={classes.packagePriceContainer} item>
        <Typography className={classes.packagePrice} variant="h1">
          <DollarIcon />
          {price}
        </Typography>
      </Grid>
    </Grid>
  );
};

const PackagesDetails = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3} justify="center">
        <Grid className={classes.gridItem} item xs={12} md={9}>
          <Card raised>
            <CardHeader
              className={classes.contactCardHeader}
              title={<Typography variant="h4">About my packages</Typography>}
              avatar={<MainLogoAvatar />}
            />
            <CardContent className={classes.contactCardContent}>
              <Typography variant="body1">
                <p>
                  All of my packages are designed to be implemented by you at home. I will guide you through your sleep plan and then support you for
                  two weeks, closely monitoring your progress to make sure that everything stays on track.
                </p>
                <p>
                  All communication is done via email and phone (or equivalent such as Skype/Whatsapp), so it doesn’t matter where you are based. If
                  you’re local to Bayside, Melbourne, depending on my schedule, we can catch up face-to-face to run through your sleep plan together
                  if you’d prefer.
                </p>
                <p>
                  To find out more about any of my packages please <Link href="/contact">contact me</Link> for a free 15 minute chat.
                </p>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid className={classes.gridItem} item xs={12} md={9}>
          <Card raised>
            <CardHeader
              className={classes.contactCardHeader}
              title={<PackageCardTitle titleText="Newborns - 14 Day Package" subtitleText="3-12 wks" price="295" />}
              avatar={<MainLogoAvatar />}
            />
            <CardContent className={classes.contactCardContent}>
              <PackageCardSummary
                summaryText="My ‘Newborns’ package is primarily focussed around creating healthy sleep habits that will set you and your baby up for a future
                 of happy sleep. Your plan will not contain any form of sleep training - newborns are far too little and need lots of our help at this age."
              />
              <WhatsIncludedSubheader />
              <CheckedLineItem lineItemText="Preliminary sleep questionnaire" />
              <CheckedLineItem lineItemText="Detailed phone consult (allow 1 to 2 hours)" />
              <CheckedLineItem
                lineItemText="Fully-personalised written sleep plan, including guidance and information on:"
                subItems={[
                  'Understanding your baby’s sleep rhythms',
                  'Gentle techniques to help settle your baby to sleep',
                  'Nap expectations and resettling techniques',
                  'Overnight sleep expectations',
                  'Feeding guidelines',
                  'Recognising your baby’s tired signs',
                  'Creating the perfect sleep environment',
                  'Understanding what sleep associations are',
                ]}
              />
              <CheckedLineItem lineItemText="Full guidance on how to implement your plan at home" />
              <CheckedLineItem lineItemText="Interactive online log to track your progress (optional for newborns)" />
              <CheckedLineItem lineItemText="Two weeks of phone, text and email support" />
              <CheckedLineItem lineItemText="Final wrap-up call, to make sure you understand any next steps" />
              <CheckedLineItem lineItemText="Personalised information pack for the future" />
              <ContactMeDetails />
            </CardContent>
          </Card>
        </Grid>

        <Grid className={classes.gridItem} item xs={12} md={9}>
          <Card raised>
            <CardHeader
              className={classes.contactCardHeader}
              title={<PackageCardTitle titleText="Infants - 14 Day Package" subtitleText="3 months - 2 yrs" price="365" />}
              avatar={<MainLogoAvatar />}
            />
            <CardContent className={classes.contactCardContent}>
              <PackageCardSummary
                summaryText="Are you exhausted from being awake every 2 hours overnight with your 4-month old?
               Or maybe your 18 month old has started waking up at 5am and refuses to nap during the day? Whatever the issue is, my ‘Infants’ package can help."
              />
              <WhatsIncludedSubheader />
              <CheckedLineItem lineItemText="Preliminary sleep questionnaire" />
              <CheckedLineItem lineItemText="Detailed phone consult (allow 1 to 2 hours)" />
              <CheckedLineItem
                lineItemText="Fully-personalised sleep plan including guidance and information on:"
                subItems={[
                  'Understanding your child’s sleep rhythms',
                  'Sleep environment and bedding',
                  'Sleep associations',
                  'Settling techniques, tailored to your child and your parenting style',
                  'Nap expectations and nap resettling',
                  'Age-appropriate bedtimes',
                  'Overnight sleep expectations and resettling',
                  'The importance of nutrition for sleep',
                  'Overnight feeding expectations',
                  'Sleep regressions',
                  'Suggested routine',
                ]}
              />
              <CheckedLineItem lineItemText="Step-by-step guidance on how to implement your plan at home" />
              <CheckedLineItem lineItemText="Interactive online log to track your progress" />
              <CheckedLineItem lineItemText="Two weeks of phone, email and text support" />
              <CheckedLineItem lineItemText="Final wrap-up call, to make sure you understand any next steps" />
              <CheckedLineItem lineItemText="Personalised information pack for the future" />
              <ContactMeDetails />
            </CardContent>
          </Card>
        </Grid>

        <Grid className={classes.gridItem} item xs={12} md={9}>
          <Card raised>
            <CardHeader
              className={classes.contactCardHeader}
              title={<PackageCardTitle titleText="Toddlers - 14 Day Package" subtitleText="2-5 yrs" price="365" />}
              avatar={<MainLogoAvatar />}
            />
            <CardContent className={classes.contactCardContent}>
              <PackageCardSummary
                summaryText="Does your 4-year old hop into bed with you multiple times a night? Maybe you need to lie with your child
                 until they fall asleep? Or perhaps you can no longer start your day at 5am, when your 3-year old wakes up?
                 Whatever the issue, my ‘Toddlers’ package can help."
              />
              <WhatsIncludedSubheader />
              <CheckedLineItem lineItemText="Preliminary sleep questionnaire" />
              <CheckedLineItem lineItemText="Detailed phone consult (allow up to 1 hour)" />
              <CheckedLineItem
                lineItemText="Fully-personalised sleep plan including guidance and information on:"
                subItems={[
                  'Understanding your child’s sleep rhythms',
                  'Sleep environment and bedding',
                  'Sleep associations',
                  'Settling techniques, tailored to your child and your parenting style',
                  'Strategies to help your toddler understand the plan',
                  'Nap expectations',
                  'Age-appropriate bedtimes',
                  'Strategies to avoid bedtime battles',
                  'Techniques to teach your child to stay in their own bed all night',
                  'The importance of nutrition for sleep',
                  'Sleep regressions',
                  'Suggested routine',
                ]}
              />
              <CheckedLineItem lineItemText="Step-by-step guidance on how to implement your plan at home" />
              <CheckedLineItem lineItemText="Interactive online log to track your progress" />
              <CheckedLineItem lineItemText="Two weeks of phone and email support" />
              <CheckedLineItem lineItemText="Final wrap-up call, to make sure you understand any next steps" />
              <CheckedLineItem lineItemText="Personalised information pack for the future" />
              <ContactMeDetails />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PackagesDetails;
