import { ThemeProvider } from '@material-ui/styles';
import * as React from 'react';
import BannerImage from '../../images/packages.jpg';
import materialTheme from '../../theme/material-theme';
import PackagesDetails from './PackagesDetails';
import { PackagesWrapper } from './style';
import { BannerWrapper } from '../../components/common-styles';

interface AboutProps {}

const BannerComponent = BannerWrapper(BannerImage);

const About: React.FunctionComponent<AboutProps> = () => {
  return (
    <ThemeProvider theme={materialTheme}>
      <BannerComponent />
      <PackagesWrapper>
        <PackagesDetails />
      </PackagesWrapper>
    </ThemeProvider>
  );
};

export default About;
