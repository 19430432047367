import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Packages from '../containers/Packages';

const PackagesPage = () => {
  return (
    <Layout>
      <SEO title="Packages" description="This page details all of the services provided by Sweet Dreams Jellybean. Certified sleep consultant." />
      <Packages />
    </Layout>
  );
};

export default PackagesPage;
